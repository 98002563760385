import { ref } from 'vue'
export default function ClassGetList(http, constant, store) {
    let class_loading = ref(false)
    let class_list = ref([])

    async function getClassList() {
        class_loading.value = true;
        class_list.value = []
        try {
            const response = await http.post(constant.CLASS_LIST, [])
            class_loading.value = false
            class_list.value = response.data.data
        } catch (err) {
            class_loading.value = false;
        }
    }
    return {
        class_loading, class_list, getClassList
    }
}